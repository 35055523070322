<template>
    <div class="container_con">
        <el-card>
            <div class="con_title"><span></span> 新增</div>
            <el-form ref="form" :rules="rules" :model="form" label-width="150px">
                <el-form-item label="（上游）公司名称：" prop="title">
                    <el-input v-model="form.title" maxlength="20" placeholder="请输入（上游）公司名称" style="width:300px" />
                </el-form-item>
                <el-form-item label="联系人：" prop="contact_person">
                    <el-input v-model="form.contact_person" maxlength="20" placeholder="请输入联系人" style="width:300px" />
                </el-form-item>
                <el-form-item label="联系电话：" prop="contact_number">
                    <el-input v-model="form.contact_number" maxlength="20" placeholder="请输入联系电话" style="width:300px" />
                </el-form-item>
                <el-form-item label="产品前缀：" prop="list">
                    <el-select multiple v-model="list" placeholder="请选择产品前缀" style="width:300px; margin-right: 15px;"
                        filterable>
                        <el-option :label="item.value + '__' + item.title" v-for="(item, index) in zjlist" :key="index"
                            :value="item.value"></el-option>
                    </el-select>
                    <!-- <el-input v-model="form.product_prefix" placeholder="请输入产品前缀" style="width:300px" /> -->
                </el-form-item>
                <el-form-item label="数据天数：" prop="query_days">
                    <el-input v-model="form.query_days" placeholder="请输入数据天数" style="width:300px" />
                </el-form-item>
                <el-form-item label="自己的代码：" prop="self_no">
                    <el-input v-model="form.self_no" placeholder="请输入自己的代码" style="width:300px" />
                </el-form-item>
                <el-form-item label="自己的名称：" prop="self_name">
                    <el-input v-model="form.self_name" placeholder="自己的名称" style="width:300px" />
                </el-form-item>
            </el-form>
        </el-card>
    </div>
    <div class="bottom_btn">
        <el-button type="primary" @click="submitForm('form')" :loading="is_ajax">提交</el-button>
        <el-button @click="$router.go(-1)">取消</el-button>
    </div>
</template>

<script>
export default {
    name: "addPosition",
    data() {
        return {
            obj: '',
            is_ajax: false,
            form: {
                title: '',
                contact_person: '',
                contact_number: '',
                product_prefix: '',
                query_days: '',
                self_no: '',
                self_name: '',
                // sort: 0
            },
            rules: {
                title: [
                    { required: true, message: "标题不能为空", trigger: "blur" }
                ],
                // product_prefix: [
                //     { required: true, message: "产品前缀不能为空", trigger: ['blur', 'change'], }
                // ],
                query_days: [
                    { required: true, message: "数据天数不能为空", trigger: "blur" }
                ],
                self_no: [
                    { required: true, message: "自己的代码不能为空", trigger: "blur" }
                ],
                self_name: [
                    { required: true, message: "自己的名称不能为空", trigger: "blur" }
                ],
            },
            zjlist: [],
            list:[]
        };
    },
    created() {
        this.obj = this.$route.query.obj || '';
        console.log(this.obj, 'obj');
        if (this.obj) {
            this.obj = JSON.parse(this.obj);
            console.log(this.obj, 'obj');
            this.get_data()
        }
        this.getOptions()
    },
    methods: {
        // 表单重置
        reset() {
            this.form = {
                title: '',
                contact_person: '',
                contact_number: '',
                product_prefix: '',
                query_days: '',
                self_no: '',
                self_name: '',
            };
            this.list=[];
            if (this.$refs['form']) {
                this.$refs['form'].clearValidate();
            }
        },
        /** 提交按钮 */
        submitForm: function (form) {
            this.$refs[form].validate((valid) => {
                if(this.list.length == 0) return this.$message.success('产品前缀不能为空')
                this.form.product_prefix = this.list.join(',')
                if (valid) {
                    this.is_ajax = true;
                    if (form == 'form') {
                        if (this.obj.id) {
                            this.$httpPost("/backend/api.Settings/edit?id=" + this.obj.id, this.form).then((res) => {
                                if (res.status == 200) {
                                    this.$message.success('更新成功');
                                    this.$router.go(-1)
                                } else {
                                    this.$message.error(res.message);
                                }
                                this.is_ajax = false;
                            }).catch((err) => {
                                console.log(err);
                                this.is_ajax = false;
                            });
                        } else {
                            this.$httpPost("/backend/api.Settings/add", this.form).then((res) => {
                                if (res.status == 200) {
                                    this.$message.success('新增成功');
                                    // this.reset();
                                    this.$router.go(-1)
                                } else {
                                    this.$message.error(res.message);
                                }
                                this.is_ajax = false;
                            }).catch((err) => {
                                console.log(err);
                                this.is_ajax = false;
                            });
                        }
                    }
                }
            });
        },
        get_data() {
            this.form = {
                title: this.obj.title,
                contact_person: this.obj.contact_person,
                contact_number: this.obj.contact_number,
                product_prefix: this.obj.product_prefix.split(','),
                query_days: this.obj.query_days,
                self_no: this.obj.self_no,
                self_name: this.obj.self_name,
                // sort: 0
            }
            this.list = this.obj.product_prefix.split(',')
            console.log(this.form,'form');
        },
        getOptions() {
            this.$httpGet("/backend/SysConfig/index", {
                size: 10000000,
                groups: '产品编号前缀'
            }).then((res) => {
                if (res.status == 200) {
                    this.zjlist = res.data.data;
                    // if (!this.id && this.zjlist.length > 0) {
                    //     this.form.product_no = this.zjlist[0].value
                    // }
                } else {
                    this.$message.error(res.message);
                }
            }).catch((err) => {
                console.log(err);
            });
        }
    }

};
</script>

<style lang="scss" scoped></style>
